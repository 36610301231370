import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Row, Col, Select, Button, Modal, Spin, Tooltip, Divider, Avatar, message } from "antd";
import QueueAnim from "rc-queue-anim";
import enquire from "enquire.js";
import axios from "axios";
import moment from "moment";

import qs from 'querystring'

import { debounce } from 'lodash'; 

import { DESKTOP_DOWN } from "../../config/mediaQueries";
import { API_ERRO_TYPE_CANCEL } from "./../../config/general";
import { timeCardService, columnsService, employeesService, companiesService } from "./../../redux/services";
import * as floatbox from "./../../helpers/floatbox";
import Table from "./Table";
import ModalColumns from "./ModalColumns";
import ModalCreate from "./ModalCreate";
import ModalEdit from "./ModalEdit";
import ModalDiscard from "./ModalDiscard";
import ModalAjustes from "./ModalAjustes";
import ModalBancoAjustes from "./ModalBancoAjustes";
import ModalFilters from "./filters";
import ModalJourneyShow from "../Journeys/show";
import FloatBox from "./floatBox";
import {
	DatePickerWithMask,
} from "./../../components";

const config = {
	title: "Cartão Ponto",
	permissionPrefix: "cartao-ponto",
};

class Index extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			isSearching: false,
			isDisabled: true,
			data: [],
			dataTotal: {},
			pagination: {
				current: 1,
				pageSize: 50,
				total: 0,
			},
			// Actions
			columnsModalVisible: false,
			ajustesModalVisible: false,
			bancoAjustesModalVisible: false,
			filtersModalVisible: false,
			activeLoadings: [],
			// Media queries
			desktopDown: false,

			//Companies
			companies: [],
  		companiesIsLoading: false,

			employees: [],
			employeesIsLoading: false,
			allEmployees: [],

			isPreviousDisabled: true,
			isNextDisabled: false,

			joinColumns: [],
			dynamicColumns: [],
			overtimeColumns: [],
			// Total
			dynamicTotalColumns: [],
			overtimeTotalColumns: [],

			columnsList: [],
			maxJoinColumns: 0,
			avatar: "",

			// Filters
			empresa_id: "",
			funcionario_id: "",
			dataInicial: moment().startOf("month").format("YYYY-MM-DD"),
			dataFinal: moment().format("YYYY-MM-DD"),
			motivo: "",
			currentEmployeeDepartment: '',

			filters: {
				empresa_id: null,
				departamento_id: null,
				funcao_id: null,
				jornada_id: null,
				status: null
			},

			journeyShowModalVisible: false,
			dataFinalError: false,
		};

		this._axiosCancelEmployeesToken = null;
	}

	componentDidMount() {
		if (!this.state.companies.length) {
			this.fetchCompanies(this.loadFirstCompany);
			this.fetchEmployees('0', true, this.loadFirstEmployee);
		}

		// Listen Media Querie sideBar
		enquire.register(DESKTOP_DOWN, {
			match: () => {
				this.setState({
					desktopDown: true,
				})
			},
			unmatch: () => {
				this.setState({
					desktopDown: false,
				})
			}
		});
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.empresa_id !== this.state.empresa_id) {
			this.fetchEmployees('0', true, this.loadFirstEmployee);
		}
	}

	componentWillUnmount() {
		// Unlisten Media Querie sideBar
		enquire.unregister(DESKTOP_DOWN);
	};

	fetchCompanies = (callback) => {
		if( this._axiosCancelCompaniesToken )
		{
			this._axiosCancelCompaniesToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelCompaniesToken = axios.CancelToken.source();

		this.setState({
			companiesIsLoading: true,
		});

		companiesService.getAutocomplete({
			orderBy    : "nome:asc",
			cancelToken: this._axiosCancelCompaniesToken.token,
		})
		.then((response) => {
			this.setState({
				companiesIsLoading: false,
				companies: response.data.data,
			}, () => {
				if (typeof callback === 'function') {
					callback();
				}
			});
		})
		.catch((data) => {
			if( data.error_type === API_ERRO_TYPE_CANCEL ) return null;

			this.setState({
				companiesIsLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	fetchEmployees = debounce((value, funcionario_id = false, callback) => {
		if (this._axiosCancelEmployeesToken) {
			this._axiosCancelEmployeesToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelEmployeesToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				employeesIsLoading: false,
				employees: [],
			});

			return false;
		}

		const { filters, allEmployees } = this.state;

		const empresa_id      = this.state.empresa_id;
		const departamento_id = filters.departamento_id;
		const jornada_id      = filters.jornada_id;
		const funcao_id       = filters.funcao_id;
		const status          = filters.status;

		this.setState({
			employeesIsLoading: true,
		});

		let params = {
			search: value,
			orderBy: "nome:asc",
			cancelToken: this._axiosCancelEmployeesToken.token,
		};

		if (empresa_id) {
			params.empresas_ids = [empresa_id];
		}

		if (departamento_id) {
			params.departamentos_ids = [departamento_id];
		}

		if (jornada_id) {
			params.horarios_ids = [jornada_id];
		}

		if (funcao_id) {
			params.funcao_ids = [funcao_id];
		}

		if (status) {
			params.status = status;
		}

		employeesService.getAutocomplete({
			...params,
		}).then((response) => {

			if (allEmployees.length === 0) {
				this.setState({
					allEmployees: response.data.data,
				});
			}
				this.setState({
					employeesIsLoading: false,
					employees: response.data.data,
				}, () => {
					if (callback) callback(response.data.data);
				});
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					employeesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	}, 500);
	loadFirstEmployee = () => {
		const { employees } = this.state;
		const [employee] = employees;
		this.selectEmployee(employee?.id);
	}

	loadFirstCompany = () => {
		const { companies } = this.state;
		const [company] = companies;
		this.selectCompany(company?.id);
	}
	
	selectCompany = (empresa_id, paginate = false) => {
		const { companies } = this.state;
		const currentIndex = companies.findIndex((company) => company.id === empresa_id);

		if (this.form && this.form.setFieldValue) {
			this.form.setFieldValue("empresa_id", empresa_id);
		}

		this.setState({
			allEmployees: [],
			empresa_id,
			isPreviousDisabled: currentIndex <= 0,
			isNextDisabled: currentIndex >= (companies.length - 1),
		}, () => {
			this.fetchGetAll(paginate);
		});
	}

	selectEmployee = (funcionario_id, paginate = false) => {
		const { employees } = this.state;
	
		const selectedEmployee = employees.find((employee) => employee.id === funcionario_id);

		const currentIndex = employees.findIndex((employee) => employee.id === funcionario_id);

		const isInEmployees = employees.some((employee) => employee.id === funcionario_id);

		let updatedEmployees = employees;
		if (!isInEmployees && selectedEmployee) {
			updatedEmployees = [...employees, selectedEmployee];
		}
	
		if (this.form && this.form.setFieldValue) {
			this.form.setFieldValue("funcionario_id", funcionario_id);
		}
	
		this.setState({
			funcionario_id,
			avatar: selectedEmployee?.avatar,
			currentEmployeeDepartment: selectedEmployee?.contrato[0]?.departamento_id,
			isPreviousDisabled: currentIndex <= 0,
			isNextDisabled: currentIndex >= (employees.length - 1),
			employees: updatedEmployees,
		}, () => {
			this.fetchGetAll(paginate);
		});
	};			

	fetchGetAll = (paginate = false) => {
		const { dataInicial, dataFinal } = this.state;
		this.fetchGetItems(paginate, dataInicial, dataFinal, true);
	}

	mergeData = item => ({
		...item,
		_ajuste_horas_falta: this.formatTime(item.ajuste_horas_falta),
		possui_batidas: this.checkBatidas(item),
	});

	reloadAll = (state, response, hasReset) => {
		if (hasReset) {
			return { 
				data:
					response.data?.data?.map((item) => this.mergeData(item)) || [],
				pagination: {
					...state.pagination,
					current: response.data?.meta?.current_page || 1,
					total: response.data?.meta?.total || 0,
				},
			};
		}

		const novaLista = state.data.map(item => {
			const element = response.data?.data.find(cartao => cartao.id === item.id);
			return element || item;
		});

		return { 
			data: novaLista?.map((item) => this.mergeData(item)) || [],			
		};
	}

	fetchGetItems = (paginate = false, dataInicialStep, dataFinalStep, hasReset = true, page = null) => {
		const { 
			isLoading, 
			isDisabled, 
			pagination, 
			funcionario_id, 
			filters, 
			empresa_id, 
			dataInicial: dataInicialOriginal, 
			dataFinal: dataFinalOriginal 
		} = this.state;

		const newPage = page || pagination.current;

		if (isLoading && isDisabled) return;

		const dataInicial = moment(dataInicialStep).isBefore(moment(dataInicialOriginal)) ? dataInicialOriginal : dataInicialStep;
		const dataFinal = moment(dataFinalStep).isAfter(moment(dataFinalOriginal)) ? dataFinalOriginal : dataFinalStep;

		// Hide all floatbox
		floatbox.hideAll();

		this.setState({ isDisabled: true, isLoading: true, isSearching: true }, () => {
			const data = {
				funcionario_id,
				datainicial: dataInicial || moment().startOf("month").format("YYYY-MM-DD"),
				datafinal: dataFinal || moment().format("YYYY-MM-DD"),
				empresa_id: empresa_id || null,
				departamento_id: filters?.departamento_id || null,
				funcao_id: filters?.funcao_id || null,
				jornada_id: filters?.jornada_id || null,
				status: filters?.status || null,
				page: paginate ? newPage : 1,
				limit: pagination.pageSize,
			}
			
			if (!paginate || !data.funcionario_id || !data.empresa_id) this.resetTable(true);
			if (!data.empresa_id || !data.funcionario_id) {
				this.setState({ isLoading: false, isDisabled: false });
				return;
			}

			timeCardService.getAllTimeCards(data)
				.then((response) => {
					this.setState(state => this.reloadAll(state, response, hasReset), async () => {
						if(response.data?.data?.length > 0) {
							await this.handleGetColumnsList(data, response.data.data[0]?.contrato, paginate);
						}
						this.setState({ isLoading: false, isDisabled: false });
					});
				})
				.catch((data) => {
					this.resetTable();
					Modal.error({
						title: "Ocorreu um erro!",
						content: String(data),
					});
				});
		});
	}

	resetTable = (isSearching = false) => {
		this.setState((state) => ({
			data: [],
			isLoading: false,
			isDisabled: isSearching,
			isSearching,
			pagination : { 
				...state.pagination, 
				current: 1, 
				total  : 0 
			} 
		}));
	}

	handleGetColumnsList = (data, contrato, paginate) => {
		columnsService.show({ empresa_id: this.state.empresa_id || contrato?.empresa_id })
			.then((response) => {
				this.setState({
					columnsList: response.data.data[0],
				}, async () => {
					this.getJoinColumns(paginate);
					this.getDynamicColumns();
					this.getOvertimeColumns();
					this.handleGetTotalTimeCard(data, () => this.setState({ isLoading: false }));
				});
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	}

	handleGetTotalTimeCard = (data, callback) => {
		const { dataInicial: datainicial, dataFinal: datafinal } = this.state;
		//TODO melhorar o fluxo (realizado fix de prod)
		delete data.datainicial;
		delete data.datafinal;

		const requestData = { ...data, datainicial, datafinal };
		timeCardService.getTotalTimeCard(requestData)
			.then((response) => {
				this.setState({
					dataTotal: {
						...response.data,
						_total_ajustado: this.formatTime(response.data.total_ajustado),
					}
				}, () => {
					this.getDynamicTotalColumns();
					this.getOvertimeTotalColumns();

					if (callback) callback();
				});
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	}

	onPaginationChange = (page, pageSize) => {
		this.setState(state => ({
			pagination: {
				...state.pagination,
				current: page,
				pageSize,
			},
		}), () => {
			this.fetchGetAll(true);
		});
	};

	formatTime = (time) => {
		if (time < 0) {
			return `-${moment().startOf("day").add(Math.abs(time), "minutes").format("HH:mm")}`;
		}

		return moment().startOf("day").add(time, "minutes").format("HH:mm");
	}

	checkBatidas = (item) => {
		const batidas = item.cartaopontobatida;
		for (let i = 0; i < batidas.length; i++) {
			const batida = batidas[i];

			if (batida.entrada_batida !== null && batida.entrada_batida !== "00:00") {
				return true;
			}

			if (batida.saida_batida !== null && batida.saida_batida !== "00:00") {
				return true;
			}
		}

		return false;
	}

	columnsOpen = () => {
		const empresa_id = this.state.data[0]?.contrato?.empresa_id || this.form.getFieldValue("empresa_id") || 0;

		if (!isNaN(empresa_id) && empresa_id > 0) {
			this.setState({columnsModalVisible: true});

			// On open screen
			this.columnsScreen.onOpen(empresa_id);
		} else {
			Modal.error({
				title: "Ocorreu um erro!",
				content: "Selecione uma empresa para gerenciar as colunas.",
			});
		}
	};

	columnsOnClose = () => {
		this.setState({columnsModalVisible: false});
	}

	columnsOnComplete = () => this.setState({ columnsModalVisible: false }, () => this.form.submit());

	journeyOpen = (journeyId) => {
		if (journeyId) {
			this.setState({ journeyShowModalVisible: true });

			// On open screen
			this.journeyShowScreen.onOpen(journeyId);
		} else {
			Modal.warning({
				title: "Ação Necessária!",
				content: "Identifique um funcionário na seleção 'Funcionário' para obter os detalhes da jornada.",
			});
		}
	}

	journeyOnClose = () => {
		this.setState({journeyShowModalVisible: false});

		document.querySelector(".ant-modal-wrap").focus();
	};

	ajustesOpen = (item) => {
		this.setState({ ajustesModalVisible: true });

		// On open screen
		this.ajustesScreen.onOpen(item);
	};

	ajustesOnClose = () => {
		this.setState({ ajustesModalVisible: false });
	}

	ajustesOnComplete = (item) => {
		this.setState({ ajustesModalVisible: false });
		this.updateRow(item);
	}

	bancoAjustesOpen = (item) => {
		this.setState( { bancoAjustesModalVisible: true });

		// On open screen
		this.bancoAjustesScreen.onOpen(item);
	};

	bancoAjustesOnClose = () => {
		this.setState({ bancoAjustesModalVisible: false });
	}

	bancoAjustesOnComplete = (item) => {
		this.setState({ bancoAjustesModalVisible: false });
		this.updateRow();
	}

	updateRow = (item) => {
		if (!item) return this.fetchGetAll(true);

		if (!item.contrato.nao_usa_banco_horas || item.status === 'justificativa_parcial' || item.usa_banco_horas) return this.fetchGetAll(true);

		const base = moment(item.data);
		const initial = base.clone().subtract(1, 'day').format('YYYY-MM-DD');
		const final = item?.jornada?.dsr_id
			? base.clone().endOf('week').add(1, 'day').format('YYYY-MM-DD')
			: base.clone().add(1, 'day').format('YYYY-MM-DD');


		this.fetchGetItems(true, initial, final, false, 1);
	}

	printOpen = () => {
		const { filters } = this.state;

		const empresa_id      = this.state.empresa_id;
		const departamento_id = filters.departamento_id;
		const jornada_id      = filters.jornada_id;
		const funcao_id       = filters.funcao_id;
		const status          = filters.status;
		const datainicial     = this.form.getFieldValue("datainicial");
		const datafinal       = this.form.getFieldValue("datafinal");
		const funcionario_id  = this.form.getFieldValue("funcionario_id");

		let params = {};

		if (empresa_id) {
			params.empresa_id = empresa_id;
		}

		if (departamento_id) {
			params.departamento_id = departamento_id;
		}

		if (datainicial) {
			params.datainicial = moment(datainicial).format("YYYY-MM-DD");
		}

		if (datafinal) {
			params.datafinal = moment(datafinal).format("YYYY-MM-DD");
		}

		if (status) {
			params.status = status;
		}

		if (jornada_id) {
			params.jornada_id = jornada_id;
		}

		if (funcao_id) {
			params.funcao_id = funcao_id;
		}

		if (funcionario_id) {
			params.funcionario_id = funcionario_id;
		}

		if (this.columns().length > 29) {
			Modal.info({
				title: 'Limite de colunas excedido!',
				content: (
					<div style={{ textAlign: 'justify' }}>
						O número de colunas de impressão ultrapassou o limite de 26, o que pode comprometer a formatação e a qualidade da impressão. Por favor, ajuste as colunas antes de prosseguir.
					</div>
				),
				maskClosable: false,
				keyboard: false,
				closable: false,
				onOk: () => {
					window.close();
				},
			})
			return;
		}
		window.open(`/impressao/timecard?${qs.stringify(params)}`, '_blank');		
	}

	reloadRow = () => {
		this.form.submit();
	}

	onEditEntradaSaida = (itemId, itemIndex, batidaIndex, sequence, type) => {
		this.editScreen.onOpen(this.state.data[itemIndex], itemIndex, batidaIndex, sequence, type, this.state.maxJoinColumns);
	}

	onChangeEntradaSaida = (value, itemId, itemIndex, batidaIndex, sequence, type, initialValue, deletePressed) => {
		let isEditing = false;
		let isBatidaOriginal = (type === 'entrada' && this.state.data[itemIndex].cartaopontobatida[batidaIndex]?.ponto_registro_entrada_id && !this.state.data[itemIndex].cartaopontobatida[batidaIndex]?.ponto_ajuste_entrada_id) || (type === 'saida' && this.state.data[itemIndex].cartaopontobatida[batidaIndex]?.ponto_registro_saida_id && !this.state.data[itemIndex].cartaopontobatida[batidaIndex]?.ponto_ajuste_saida_id);

		// Se tem a batida escolhida
		if( batidaIndex !== -1 )
		{
			if( !deletePressed && type === 'entrada' && this.state.data[itemIndex].cartaopontobatida[batidaIndex]?.ponto_ajuste_entrada_id && !!this.state.data[itemIndex].cartaopontobatida[batidaIndex]?.entrada_batida )
			{
				isEditing = true;
			}
			else if( !deletePressed && type === 'saida' && this.state.data[itemIndex].cartaopontobatida[batidaIndex]?.ponto_ajuste_saida_id && !!this.state.data[itemIndex].cartaopontobatida[batidaIndex]?.saida_batida )
			{
				isEditing = true;
			} else {
				if(deletePressed && !isBatidaOriginal) {
					this.setState({
						isLoading: true,
					});

					// Descarta batida direto
					this.discardScreen.onOpen(this.state.data[itemIndex], value, itemIndex, batidaIndex, sequence, type, this.state.motivo, initialValue, deletePressed, true);

					return false;
				}
				if(deletePressed || isBatidaOriginal) {
					this.discardScreen.onOpen(this.state.data[itemIndex], value, itemIndex, batidaIndex, sequence, type, this.state.motivo, initialValue, deletePressed);

					return false;
				}
			}
		}

		if( !isEditing )
		{
			this.createScreen.onOpen(this.state.data[itemIndex], value, itemIndex, batidaIndex, sequence, type, this.state.motivo);

			return false;
		}

		this.setState({
			isLoading: true,
		});

		const data = {
			cartao_ponto_batida_id: this.state.data[itemIndex].cartaopontobatida[batidaIndex].id,
			sequencia             : sequence,
		};

		if( type === 'entrada' )
		{
			data.hora_entrada = value.length ? `${moment(this.state.data[itemIndex].cartaopontobatida[batidaIndex]?.ponto_ajuste_entrada.data || this.state.data[itemIndex].data).format("YYYY-MM-DD")} ${value}:00` : '';
			data.motivo_entrada = value.length ? this.state.data[itemIndex].cartaopontobatida[batidaIndex].ponto_ajuste_entrada.motivo : null;
			// if( this.state.data[itemIndex].cartaopontobatida[batidaIndex].saida_batida )
			// {
			// 	data.hora_saida = `${this.state.data[itemIndex].data} ${this.state.data[itemIndex].cartaopontobatida[batidaIndex].saida_batida}:00`;
			// }
		}
		else
		{
			data.hora_saida = value.length ? `${moment(this.state.data[itemIndex].cartaopontobatida[batidaIndex]?.ponto_ajuste_saida.data || this.state.data[itemIndex].data).format("YYYY-MM-DD")} ${value}:00` : '';
			data.motivo_saida = value.length ? this.state.data[itemIndex].cartaopontobatida[batidaIndex].ponto_ajuste_saida.motivo : null;
			// if( this.state.data[itemIndex].cartaopontobatida[batidaIndex].entrada_batida )
			// {
			// 	data.hora_entrada = `${this.state.data[itemIndex].data} ${this.state.data[itemIndex].cartaopontobatida[batidaIndex].entrada_batida}:00`;
			// }
		}

		timeCardService.batidaHorarioEdit(data).then((response) => {
			this.setState({
				isLoading: false,
			});

			this.updateRow(response.data.data[0]);

			//this.reloadRow(this.state.data[itemIndex].id);
		})
		.catch((data) => {
			if( data.error_type === API_ERRO_TYPE_CANCEL ) return null;

			this.setState({
				isLoading: false,
			});

			// Reset input
			floatbox.resetInput(itemId, itemIndex, sequence, type);

			Modal.error({
				title   : "Ocorreu um erro!",
				content : String(data),
				centered: true,
			});
		});
	}

	createOnComplete = (itemId, itemIndex, sequence, type, row, motivo) => {
		if (!row || row?.usa_banco_horas) {
			this.fetchGetAll(true);
		} else {
			const base = moment(row.data);
			const initial = base.clone().subtract(1, 'day').format('YYYY-MM-DD');
			const final = row?.jornada?.dsr_id
        ? base.clone().endOf('week').add(1, 'day').format('YYYY-MM-DD')
        : base.clone().add(1, 'day').format('YYYY-MM-DD');
	
			this.fetchGetItems(true, initial, final, false, 1);
		}

		const { joinColumns } = this.state;
		joinColumns.filter((item) => item.index === sequence).map((item) => {
			item.original = true;
		});

		this.setState({
			motivo,
		});
	}

	editOnComplete = (itemId, itemIndex) => {
		this.fetchGetAll(true);
	}

	discardOnComplete = (itemId, itemIndex, sequence, type, motivo, value, batidaIndex, deletePressed) => {
		this.setState({
			isLoading: false,
		});

		this.updateRow(this.state.data[itemIndex]);

		this.setState({
			motivo,
		}, () => {
			if (deletePressed) return false;
			this.createScreen.onOpen(this.state.data[itemIndex], value, itemIndex, batidaIndex, sequence, type, this.state.motivo);
		});
	}

	/**
 	* Filter
	*/
	filtersOpen = () => {
		this.setState({ filtersModalVisible: true });

		// On open screen
		this.filtersScreen.onOpen({ ...this.state.filters });
	};

	filtersOnClose = () => {
		this.setState({ filtersModalVisible: false });
	};

	filtersOnComplete = (filters) => {
		this.setState({ filtersModalVisible: false }, () => {
			this.setState({
				filters,
			}, () => {
				this.fetchEmployees('0', true, this.loadFirstEmployee);
				this.fetchGetAll(true);
			});
		});
	};

	formatBatidaStatus = (item, batida) => {
		// Status individuais
		if (batida) {
			if(batida?.ponto_ajustes_justificativa_falta_id && !item?.feriado_id) {
				return batida?.ponto_ajustes_justificativa_falta?.justificativa?.nome ?? "Justificativa";
			}
		}

		if (item?.afastamento?.justificativa_id && !item?.feriado_id) {
			return item?.afastamento?.justificativa?.nome ?? "Justificativa";
		}

		if (item.possui_batidas) {
			return null;
		}

		// Status de dia todo
		if (item?.feriado_id) {
			return "Feriado";
		}

		if (item?.pontoajuste?.justificativa_id) {
			return item?.pontoajuste?.justificativa?.nome ?? "Justificativa";
		}  

		if (item?.folga) {
			return "Folga";
		} 
		
		if (item?.feriado_id && item?.afastamento_id) {
			return item?.afastamento?.justificativa?.nome;
		}
		
		if (item?.dia_especial) {
			return "";
		}  
		
		if (item.status === "dia_sem_jornada") {
			return "";
		}

		if (item?.status === "falta") {
			return "Falta";
		}

		return null;
	}

	formatBatidaCanEdit = (item) => {
		if (item.status === 'afastamento') {
			return false;
		}

		return true;
	}

	onGoToEntradaSaida = (to, itemId, itemIndex, batidaIndex, sequence, type) => {
		this.setState({
			isLoading: true,
		});

		const row     = this.state.data[itemIndex];
		const batida  = row.cartaopontobatida[batidaIndex];
		const dataDia = row.data;

		const data = {
			cartao_ponto_id       : row.id,
			cartao_ponto_batida_id: batida.id,
			sequencia             : sequence,
			to                    : to,
			type                  : type,
		};

		if( batida.entrada_batida )
		{
			data.entrada_batida = `${dataDia} ${batida.entrada_batida}:00`;
		}

		if( batida.saida_batida )
		{
			data.saida_batida = `${dataDia} ${batida.saida_batida}:00`;
		}

		if( batida.ponto_ajuste_entrada_id )
		{
			data.ponto_ajuste_entrada_id = batida.ponto_ajuste_entrada_id;
		}

		if( batida.ponto_ajuste_saida_id )
		{
			data.ponto_ajuste_saida_id = batida.ponto_ajuste_saida_id;
		}

		timeCardService.movimentarBatida(data).then((response) => {
			this.setState({
				isLoading: false,
			});

			this.updateRow(response.data.data[0]);

			//this.reloadRow(this.state.data[itemIndex].id);
		})
		.catch((data) => {
			if( data.error_type === API_ERRO_TYPE_CANCEL ) return null;

			this.setState({
				isLoading: false,
			});

			// Reset input
			floatbox.resetInput(itemId, itemIndex, sequence, type);

			Modal.error({
				title   : "Ocorreu um erro!",
				content : String(data),
				centered: true,
			});
		});
	}

	getInitialValueInputOutput = (status, batida, fieldName, rowIndex) => {
		const { data } = this.state;

    const rowData = data[rowIndex];
    const isHoliday = rowData.status === 'feriado';
    const hasBatidas = rowData.cartaopontobatida.length > 0;

    if (batida?.[fieldName]) {
			return batida[fieldName];
    }

    if (status) {
			return status;
    }

    if (isHoliday && !hasBatidas) {
			return rowData.status_nome;
    }

    return "";
	};

	getJoinColumns = (pagination) => {
		const {data, maxJoinColumns} = this.state;

		let columns = [];
		let max = 0;

		data.forEach((item) => {
			let totalBatidasJornada = item.jornadadiasemana?.jornada_batidas?.length ?? 0;
			let totalBatidasCartao  = item.cartaopontobatida?.length ?? 0;

			if(totalBatidasJornada) {
				// Pega a sequencia do ultima batida, api ja retorna por sequencia asc
				const maxJornada = item.jornadadiasemana.jornada_batidas[totalBatidasJornada - 1].sequencia;

				if( maxJornada > max )
				{
					max = maxJornada;
				}
			}

			if( totalBatidasCartao )
			{
				// Pega a sequencia do ultima batida, api ja retorna por sequencia asc
				const maxBatida = item.cartaopontobatida[totalBatidasCartao - 1].sequencia;

				if( maxBatida > max )
				{
					max = maxBatida;
				}
			}

			if (item?.jornada?.tipo_calculo === 'horista' && max === 0) {
				max = 3;
			}
		});

		if (pagination) {
			max = Math.max(max, maxJoinColumns);
		}

		this.setState({
			maxJoinColumns: max,
		});

		[...Array(max).keys()].map((index) => {
			columns.push(
				{
					title: 'Entrada ' + (index + 1),
					index: index + 1,
					width: 100,
					className: "no-ellipsis no-padding-horizontal text-center text-capitalize",
					original: true,
					render: (item, row_index) => {
						const batidaIndex = item.cartaopontobatida.findIndex(batida => batida.sequencia === index + 1);
						const status = this.formatBatidaStatus(item, batidaIndex === -1 ? null : item.cartaopontobatida[batidaIndex]);
						const canEdit = this.formatBatidaCanEdit(item);

						let isManualPunchResult = false;

						const isManualPunch = (item, index) => {
							const batidaIndex = item.cartaopontobatida.findIndex(batida => batida.sequencia === index + 1);
							return isManualPunchResult = batidaIndex !== -1 && (item?.cartaopontobatida[batidaIndex]?.ponto_ajuste_entrada?.tipo === 'inclusao');
						};

						const isPreMarked = (item, index) => {
							const batidaIndex = item.cartaopontobatida.findIndex(batida => batida.sequencia === index + 1);
							if (isManualPunchResult || item?.cartaopontobatida[batidaIndex]?.ponto_registro_entrada?.tipo === 'Original') return false;

							return batidaIndex !== -1 && item.status !== 'dia_sem_jornada' &&
								(item.cartaopontobatida[batidaIndex].entrada_auto_jornada === 1 || item?.cartaopontobatida[batidaIndex]?.ponto_ajuste_entrada?.tipo === 'Pré-assinalado');
						};
						
						const initialValue = this.getInitialValueInputOutput(status, item.cartaopontobatida[batidaIndex], 'entrada_batida', row_index)

						return !this.state.isLoading && (
							<div>
								<FloatBox
									canEdit={canEdit}
									onEdit={this.onEditEntradaSaida}
									onChange={this.onChangeEntradaSaida}
									onGoTo={this.onGoToEntradaSaida}
									isManualPunch={isManualPunch(item, index)}
									isPreMarked={isPreMarked(item, index)}
									status={item.status}
									itemId={item.id}
									itemIndex={row_index}
									indexBatida={batidaIndex}
									sequence={index + 1}
									type="entrada"
									canGoToLeft={batidaIndex !== -1 && index > 0}
									canGoToRight={batidaIndex !== -1}
									initialValue={initialValue}
								/>
							</div>
						)
					}
				},
				{
					title: 'Saída ' + (index + 1),
					index: index + 1,
					width: 100,
					className: 'no-ellipsis no-padding-horizontal text-center text-capitalize',
					original: true,
					render: (item, row_index) => {
						const batidaIndex = item.cartaopontobatida.findIndex(batida => batida.sequencia === index + 1);
						const status = this.formatBatidaStatus(item, batidaIndex === -1 ? null : item.cartaopontobatida[batidaIndex]);
						const canEdit = this.formatBatidaCanEdit(item);

						let isManualPunchResult = false;

						const isManualPunch = (item, index) => {
							const batidaIndex = item.cartaopontobatida.findIndex(batida => batida.sequencia === index + 1);
							return isManualPunchResult = batidaIndex !== -1 && (item?.cartaopontobatida[batidaIndex]?.ponto_ajuste_saida?.tipo === 'inclusao');
						};

						const isPreMarked = (item, index) => {
							const batidaIndex = item.cartaopontobatida.findIndex(batida => batida.sequencia === index + 1);
							if (isManualPunchResult || item?.cartaopontobatida[batidaIndex]?.ponto_registro_saida?.tipo === 'Original') return false;

							return batidaIndex !== -1 && item.status !== 'dia_sem_jornada' &&
								(item.cartaopontobatida[batidaIndex].saida_auto_jornada === 1 || item?.cartaopontobatida[batidaIndex]?.ponto_ajuste_saida?.tipo === 'Pré-assinalado');
						};

						const initialValue = this.getInitialValueInputOutput(status, item.cartaopontobatida[batidaIndex], 'saida_batida', row_index)

						return !this.state.isLoading && (
							<div>
								<FloatBox
									canEdit={canEdit}
									onEdit={this.onEditEntradaSaida}
									onChange={this.onChangeEntradaSaida}
									onGoTo={this.onGoToEntradaSaida}
									isManualPunch={isManualPunch(item, index)}
									isPreMarked={isPreMarked(item, index)}
									status={item.status}
									itemId={item.id}
									itemIndex={row_index}
									indexBatida={batidaIndex}
									sequence={index + 1}
									type="saida"
									canGoToLeft={batidaIndex !== -1}
									canGoToRight={batidaIndex !== -1 && index + 1 < max}
									initialValue={initialValue}
								/>
							</div>
						)
					}
				}
			);
		});

		this.setState({
			joinColumns: columns,
		});
	}

	getDynamicColumns = () => {
		const { columnsList } = this.state;
		let columns = [];

		if (columnsList?.apuracao_normal) {
			columns.push({
				title: 'Normal',
				width: 100,
				className: "text-center",
				render: (item) => <div>{item.horas_normal === "00:00" ? "" : item.horas_normal}</div>
			});
		}

		if (columnsList?.apuracao_faltas) {
			columns.push({
				title: 'Faltas',
				width: 100,
				className: "text-center",
				render: (item) => <div>{item.horas_falta === "00:00" ? "" : item.horas_falta}</div>
			});
		}

		if (columnsList?.apuracao_carga) {
			columns.push({
				title: 'Carga',
				width: 100,
				className: "text-center",
				render: (item) => <div>{item.carga_horaria_jornada === "00:00" ? "" : item.carga_horaria_jornada}</div>
			});
		}

		if (columnsList?.apuracao_atraso) {
			columns.push({
				title: 'Atraso',
				width: 100,
				className: "text-center",
				render: (item) => <div>{item.horas_atraso === "00:00" ? "" : item.horas_atraso}</div>
			});
		}

		if (columnsList?.apuracao_falta_dia) {
			columns.push({
				title: <div className="flexColumnCustom">Falta<br />Dia</div>,
				width: 100,
				className: "text-center",
				render: (item) => <div>{item.falta_dia_inteiro || ""}</div>
			});
		}

		if (columnsList?.apuracao_ajuste) {
			columns.push({
				title: 'Ajuste',
				width: 100,
				className: "text-center",
				render: (item) => {
					return (
						<div style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }} onClick={() => this.ajustesOpen(item)}>{item._ajuste_horas_falta === "00:00" ? "" : item._ajuste_horas_falta}</div>
					)
				}
			});
		}

		if (columnsList?.apuracao_dsr) {
			columns.push({
				title: 'Dsr',
				width: 100,
				className: "text-center",
				render: (item) => <div>{item.dsr_valor === "00:00" ? "" : item.dsr_valor}</div>
			});
		}

		if (columnsList?.apuracao_dsr_debito) {
			columns.push({
				title: <div className="flexColumnCustom">Dsr<br />Déb.</div>,
				width: 100,
				className: "text-center",
				render: (item) => <div>{item.dsr_debito === "00:00" ? "" : item.dsr_debito}</div>
			});
		}

		if (columnsList?.apuracao_adiconal_noturno) {
			columns.push({
				title: <div className="flexColumnCustom">Adic.<br />Noturno</div>,
				width: 100,
				className: "text-center",
				render: (item) => <div>{item.adicional_noturno === "00:00" ? "" : item.adicional_noturno}</div>
			});
		}

		if (columnsList?.apuracao_abono) {
			columns.push({
				title: 'Abono',
				width: 100,
				className: "text-center",
				render: (item) => <div>{item.horas_abono === "00:00" ? "" : item.horas_abono}</div>
			});
		}

		if (columnsList?.apuracao_extra) {
			columns.push({
				title: 'Extra',
				width: 100,
				className: "text-center",
				render: (item) => <div>{item.horas_extra === "00:00" ? "" : item.horas_extra}</div>
			});
		}

		this.setState({
			dynamicColumns: columns,
		});
	}

	getOvertimeColumns = () => {
		const { data, columnsList } = this.state;
		let columns = [];

		const filteredDataUsaBancoHoras = data.filter(item => item.usa_banco_horas === 1);

		// hora_extra_diurna
		// hora_extra_intervalo
		// hora_extra_noturna

		data.forEach((item) => {
			(item.hora_extra?.hora_extra_dados ?? []).forEach((dados) => {

				dados.hora_extra_faixas.forEach((faixa) => {
					const index = columns.findIndex((column) => column.percentual === faixa.percentual && column.periodo === dados.periodo);

					if( index === -1 )
					{
						if( (columnsList?.hora_extra_diurna && dados.periodo === "diurno") || (columnsList?.hora_extra_intervalo && dados.periodo === "intervalo") || (columnsList?.hora_extra_noturna && dados.periodo === "noturno") )
						{
							columns.push(
								{
									idsFaixa  : [faixa.id],
									percentual: faixa.percentual,
									periodo   : dados.periodo,
									title     : <div className="flexColumnCustom">Extra  <br />{+ parseFloat(faixa.percentual) + "%" + dados.periodo.charAt(0).toUpperCase()}</div>,
									width     : 100,
									className : "no-ellipsis text-center",
								},
							);
						}
					}
					else
					{
						columns[index].idsFaixa.push(faixa.id);
					}
				});
			});
		});

		columns.forEach((column) => {
			column.render = (item) => {
				let value = "";
				item.cartaopontohorasextras.forEach((extra) => {
					if( column.idsFaixa.includes(extra.horas_extras_faixa_id) )
					{
						value = extra.quantidade;
						return false;
					}
				});

				return (
					<div>{value}</div>
				)
			};
		});

		if( columnsList?.banco_hora && filteredDataUsaBancoHoras.length > 0 )
		{
			columns.push({
				title    : 'BCréd',
				width    : 100,
				className: "text-center colorBCred",
				render: (item) => <div>{item.horas_banco_extras === "00:00" ? "" : item.horas_banco_extras}</div>
			});

			columns.push({
				title    : 'BDéb',
				width    : 100,
				className: "text-center colorBDeb",
				render: (item) => <div>{item.horas_banco_falta === "00:00" ? "" : item.horas_banco_falta}</div>
			});

			columns.push({
				title: 'BTotal',
				width: 100,
				className: "text-center",
				render: (item) => {
					const isNegative = item.horas_banco_total?.startsWith('-');
					const colorClass = isNegative ? "colorBDeb" : "colorBCred";
					return (
						<div className={`${colorClass}`}>
							{item.horas_banco_total === "00:00" ? "" : item.horas_banco_total}
						</div>
					);
				}
			});

			columns.push({
				title    : 'BSaldo',
				width    : 100,
				className: "text-center",
				render: (item) => {
					const isNegative = item.horas_banco_saldo?.startsWith('-');
					const colorClass = isNegative ? "colorBDeb" : "colorBCred";
					return (
						<div className={`${colorClass}`}>
							{item.horas_banco_saldo === "00:00" ? "00:00" : item.horas_banco_saldo}
						</div>
					);
				}
			});

			columns.push({
				title    : 'BAjuste',
				width    : 100,
				className: "text-center",
				render: (item) => {
					const isNegative = item.horas_banco_ajustes?.startsWith('-');
					const colorClass = isNegative ? "colorBDeb" : "colorBCred";
					return (
						<div 
							className={`${colorClass}`} 
							style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
							onClick={() => this.bancoAjustesOpen(item)}
						>
							<div>{item.horas_banco_ajustes === "00:00" ? "" : item.horas_banco_ajustes}</div>
						</div>
					);
				}
			});
		}

		this.setState({
			overtimeColumns: columns,
		});
	}

	columns = () => {
		return [
			{
				title : 'Data',
				width : 130,
				render: (item) =>
					<div>{moment(item?.data).format("DD/MM/YYYY-ddd")}</div>
			},
			{
				title    : 'Status',
				width    : 75,
				className: "status",
				render   : (item) =>
					<Tooltip placement="top" title={this.getStatusTitle(item)} trigger="hover">
						<div className="inner status-circle">
							<span style={{backgroundColor: item?.status_cor ?? "#000"}}></span>
						</div>
					</Tooltip>,
			},
			{
				title    : 'Ações',
				width    : 55,
				className: "actions",
				render   : () => {
				}
			},
			{
				title: 'Previsto',
				width: 105,
				className: "previsto",
				render: (item) => {
					const hasThirdJourney = item?.jornadadiasemana?.jornada_batidas[2]?.entrada;
					const journeyList = item?.jornadadiasemana?.jornada_batidas;
					let journeyListToShow = [];

					if (hasThirdJourney) {
						const [firstJourney, secondJourney] = journeyList;
						journeyListToShow = [firstJourney, secondJourney];
					} else if (journeyList?.length > 0) {
						journeyListToShow = journeyList;
					}

					const journeyRender = (lista, isTooltip) => {
            return (
              <div>
                {lista.map((batida, index) => {
                  if (!batida.entrada || !batida.saida) {
                    return null;
                  }

                  const shouldDisplay =
                    !item?.folga && !item?.feriado_id && !item?.dia_especial;

                  return (
                    <Fragment key={index}>
                      <span
                        style={{ display: shouldDisplay ? "inline" : "none" }}
                      >
                        {batida.entrada} - {batida.saida}
                      </span>
                      <br />
                    </Fragment>
                  );
                })}
                {hasThirdJourney && !isTooltip && (
                  <div className="overflow_journey_indicator">...</div>
                )}
              </div>
            );
          };

					return (
            <div className="inner">
              <Tooltip
                title={
                  hasThirdJourney
                    ? journeyRender(journeyList, true)
                    : ""
                }
              >
                {journeyRender(journeyListToShow)}
              </Tooltip>
            </div>
          );
				}
			},
			...this.state.joinColumns,
			// {
			// 	title      : null,
			// 	titleRender: <Button type="primary" shape="circle" icon={<i className="far fa-plus" />} onClick={this.newJoinColumn}></Button>,
			// 	className  : "last-column",
			// 	width      : 40,
			// 	render     : () => <Fragment></Fragment>
			// },
			...this.state.dynamicColumns,
			...this.state.overtimeColumns,
		]
	}

	getStatusTitle = (row) => {
		if (row?.status_nome === 'Dia sem jornada') {
			return 'Dia sem horário'
		}

		return row?.status_nome ?? ""
	}

	columnsTotal = () => {
		return [
			{
				title : 'Total',
				width : 365 + (this.state.maxJoinColumns * 100 * 2),
				render: () => 'Total'
			},
			...this.state.dynamicTotalColumns,
			...this.state.overtimeTotalColumns,
		]
	}

	getDynamicTotalColumns = () => {
		const {columnsList, dataTotal} = this.state;
		let columns                    = [];

		if( columnsList?.apuracao_normal )
		{
			columns.push({
				title    : 'Normal',
				width    : 100,
				className: "text-center",
				render   : () => <div>{dataTotal.total_horas_normais || ""}</div>
			});
		}

		if( columnsList?.apuracao_faltas )
		{
			columns.push({
				title    : 'Faltas',
				width    : 100,
				className: "text-center",
				render   : () => <div>{dataTotal.total_horas_faltas || ""}</div>
			});
		}

		if( columnsList?.apuracao_carga )
		{
			columns.push({
				title    : 'Carga',
				width    : 100,
				className: "text-center",
				render   : () => <div>{dataTotal.total_horas_carga || ""}</div>
			});
		}

		if( columnsList?.apuracao_atraso )
		{
			columns.push({
				title    : 'Atraso',
				width    : 100,
				className: "text-center",
				render   : () => <div>{dataTotal.total_horas_atraso || ""}</div>
			});
		}

		if( columnsList?.apuracao_falta_dia )
		{
			columns.push({
				title    : 'Falta/Dia',
				width    : 100,
				className: "text-center",
				render   : () => <div>{dataTotal?.total_falta_dia}</div>
			});
		}

		if( columnsList?.apuracao_ajuste )
		{
			columns.push({
				title    : 'Ajuste',
				width    : 100,
				className: "text-center",
				render   : () => <div>{dataTotal?.total_ajustado}</div>
			});
		}

		if( columnsList?.apuracao_dsr )
		{
			columns.push({
				title    : 'Dsr',
				width    : 100,
				className: "text-center",
				render   : () => <div>{dataTotal.total_dsr || ""}</div>
			});
		}

		if( columnsList?.apuracao_dsr_debito )
		{
			columns.push({
				title    : 'Dsr Déb.',
				width    : 100,
				className: "text-center",
				render   : () => <div>{dataTotal.total_dsr_debito || ""}</div>
			});
		}

		if( columnsList?.apuracao_adiconal_noturno )
		{
			columns.push({
				title    : 'Adic. Noturno',
				width    : 100,
				className: "text-center",
				render   : () => <div style={{ whiteSpace: 'normal' }}>{dataTotal.total_adiconal_noturo || ""}</div>
			});
		}

		if( columnsList?.apuracao_abono )
		{
			columns.push({
				title    : 'Abono',
				width    : 100,
				className: "text-center",
				render   : () => <div>{dataTotal.total_abono || ""}</div>
			});
		}

		if( columnsList?.apuracao_extra )
		{
			columns.push({
				title    : 'Extra',
				width    : 100,
				className: "text-center",
				render   : () => <div>{dataTotal.total_horas_extras || ""}</div>
			});
		}

		this.setState({
			dynamicTotalColumns: columns,
		});
	}

	getOvertimeTotalColumns = () => {
		const { data, dataTotal, columnsList } = this.state;
		let columns = [];

		const filteredDataUsaBancoHoras = data.filter(item => item.usa_banco_horas === 1);

		// hora_extra_diurna
		// hora_extra_intervalo
		// hora_extra_noturna

		data.forEach((item) => {
			(item.hora_extra?.hora_extra_dados ?? []).forEach((dados) => {
				dados.hora_extra_faixas.forEach((faixa) => {
					const index = columns.findIndex((column) => column.percentual === faixa.percentual && column.periodo === dados.periodo);

					if( index === -1 )
					{
						if( (columnsList?.hora_extra_diurna && dados.periodo === "diurno") || (columnsList?.hora_extra_intervalo && dados.periodo === "intervalo") || (columnsList?.hora_extra_noturna && dados.periodo === "noturno") )
						{
							columns.push(
								{
									idsFaixa  : [faixa.id],
									percentual: faixa.percentual,
									periodo   : dados.periodo,
									title     : 'Extra ' + parseFloat(faixa.percentual) + "%" + dados.periodo.charAt(0).toUpperCase(),
									width     : 100,
									className : "no-ellipsis text-center",
								},
							);
						}
					}
					else
					{
						columns[index].idsFaixa.push(faixa.id);
					}
				});
			});
		});

		columns.forEach((column) => {
			column.render = () => {
				let value = "00:00";
				if (dataTotal?.horas_extras) {
					Object.keys(dataTotal.horas_extras).forEach((key) => {
						if (column.idsFaixa.includes(parseInt(key))){
							value = dataTotal.horas_extras[key];
							return false;
						}
					});
				}

				return (
					<div>{value}</div>
				)
			};
		});

		const createBankHoursTotalColumn = (title, dataKey) => ({
			title,
			width: 100,
			className: "text-center",
			render: () => {
				const isNegative = dataTotal?.[dataKey]?.startsWith('-');
				const colorClass = isNegative ? 'colorBDeb' : 'colorBCred';
				return (
						<div className={`${colorClass}`}>
								{dataTotal[dataKey] || ""}
						</div>
				);
			}
		});
	
		if (columnsList?.banco_hora && filteredDataUsaBancoHoras.length > 0) {
			columns.push(createBankHoursTotalColumn('BCréd', 'bh_horas_extras'));
			columns.push(createBankHoursTotalColumn('BDéb', 'bh_horas_falta'));
			columns.push(createBankHoursTotalColumn('BTotal', 'bh_horas_total'));
			columns.push(createBankHoursTotalColumn('BSaldo', 'bh_horas_saldo'));
			columns.push(createBankHoursTotalColumn('BAjuste', 'bh_horas_ajuste'));
		}
	
		this.setState({
			overtimeTotalColumns: columns,
		});
	}

	newJoinColumn = () => {
		const { isSearching, joinColumns } = this.state;
		let index = joinColumns.length / 2;

		if (!isSearching) this.fetchGetAll(true);

		joinColumns.push(
			{
				title: 'Entrada ' + (index + 1),
				index: index + 1,
				width: 100,
				className: "no-ellipsis no-padding-horizontal text-center text-capitalize",
				original: false,
				render: (item, row_index) => {
					const batidaIndex = item.cartaopontobatida.findIndex(batida => batida.sequencia === index + 1);
					const status = this.formatBatidaStatus(item, batidaIndex === -1 ? null : item.cartaopontobatida[batidaIndex]);
					const canEdit = this.formatBatidaCanEdit(item);

					return !this.state.isLoading && (
						<div>
							<FloatBox
								canEdit={canEdit}
								onEdit={this.onEditEntradaSaida}
								onChange={this.onChangeEntradaSaida}
								onGoTo={this.onGoToEntradaSaida}
								itemId={item.id}
								itemIndex={row_index}
								indexBatida={batidaIndex}
								sequence={index + 1}
								type="entrada"
								canGoToLeft={batidaIndex !== -1 && index > 0}
								canGoToRight={batidaIndex !== -1}
								initialValue={status ?? item.cartaopontobatida[batidaIndex]?.entrada_batida ?? ""}
							/>
						</div>
					)
				}
			},
			{
				title: 'Saída ' + (index + 1),
				index: index + 1,
				width: 100,
				className: "no-ellipsis no-padding-horizontal text-center text-capitalize",
				original: false,
				render: (item, row_index) => {
					const batidaIndex = item.cartaopontobatida.findIndex(batida => batida.sequencia === index + 1);
					const status = this.formatBatidaStatus(item, batidaIndex === -1 ? null : item.cartaopontobatida[batidaIndex]);
					const canEdit = this.formatBatidaCanEdit(item);

					return !this.state.isLoading && (
						<div>
							<FloatBox
								canEdit={canEdit}
								onEdit={this.onEditEntradaSaida}
								onChange={this.onChangeEntradaSaida}
								onGoTo={this.onGoToEntradaSaida}
								itemId={item.id}
								itemIndex={row_index}
								indexBatida={batidaIndex}
								sequence={index + 1}
								type="saida"
								canGoToLeft={batidaIndex !== -1}
								canGoToRight={batidaIndex !== -1 && index + 1 < this.state.maxJoinColumns}
								initialValue={status ?? item.cartaopontobatida[batidaIndex]?.saida_batida ?? ""}
							/>
						</div>
					)
				}
			}
		);

		this.setState({
			joinColumns,
			maxJoinColumns: this.state.maxJoinColumns + 1,
		});
	}

	removeBatida = () => {
		const {joinColumns} = this.state;

		if( joinColumns.length > 2 && !joinColumns[joinColumns.length - 1].original )
		{
			joinColumns.pop();
			joinColumns.pop();

			this.setState({
				joinColumns,
				maxJoinColumns: this.state.maxJoinColumns - 1,
			});
		} else {
			message.warning("Não é possível remover colunas originais!");
		}
	}

	onFinish = (values) => {
		const { filters } = this.state;

		this.setState({
			departamento_id: filters.departamento_id,
			funcao_id      : filters.funcao_id,
			jornada_id     : filters.jornada_id,
			status         : filters.status,
			empresa_id     : values.empresa_id,
			dataInicial    : values.datainicial.format("YYYY-MM-DD"),
			dataFinal      : values.datafinal.format("YYYY-MM-DD"),
		}, () => {
			this.selectEmployee(values.funcionario_id);
		});
	};

	/**
 	 * Avança para o próximo funcionário na lista de funcionários.
 	 */
	goToNextEmployee = async () => {
		const { employees } = this.state;

		// await this.fetchEmployees('0', true, () => {
			const currentEmployeeId = this.form.getFieldValue("funcionario_id");
			const currentIndex = employees.findIndex((employee) => employee.id === currentEmployeeId);
		
			if (currentIndex < 0 || currentIndex >= employees.length - 1) return;
		
			const nextEmployeeId = employees[currentIndex + 1]?.id;
			if (!nextEmployeeId) return;
	
			this.selectEmployee(nextEmployeeId, true);
		// });
	};
	
	goToPreviousEmployee = async () => {
		const { employees } = this.state;
		// await this.fetchEmployees('0', true, () => {
			const currentEmployeeId = this.form.getFieldValue("funcionario_id");
			const currentIndex = employees.findIndex((employee) => employee.id === currentEmployeeId);

			if (currentIndex <= 0) return;
		
			const previousEmployeeId = employees[currentIndex - 1]?.id;
			if (!previousEmployeeId) return;

			this.selectEmployee(previousEmployeeId, true);
		// });
	};

	goToNextCompany = () => {
		const { companies } = this.state;

		const currentCompanyId = this.form.getFieldValue("empresa_id");

		const currentIndex = companies.findIndex((company) => company.id === currentCompanyId);

		const nextCompanyId = companies[currentIndex + 1]?.id;

		if (!nextCompanyId) return;
		this.selectCompany(nextCompanyId, true);
	}

	goToPreviousCompany = () => {
		const { companies } = this.state;

		const currentCompanyId = this.form.getFieldValue("empresa_id");

		const currentIndex = companies.findIndex((company) => company.id === currentCompanyId);

		const previousCompanyId = currentIndex > 0 ? companies[currentIndex - 1]?.id : null;

		if (!previousCompanyId) return;
		this.selectCompany(previousCompanyId, true);
	}

	render() {
		const { 
			isLoading, 
			isSearching, 
			employees, 
			employeesIsLoading, 
			empresa_id, 
			companies, 
			dataInicial, 
			dataFinal, 
			dataFinalError,
			isDisabled,
		} = this.state;

		return (
			<QueueAnim className="site-content-inner page-timecard alternative-wrapper">
				<div className="page-content" key="1">
					<h1 className="page-title">{config.title}</h1>
					<Form
						ref={el => this.form = el}
						layout="vertical"
						scrollToFirstError
						disabled={isLoading}
						onFinish={this.onFinish}
						initialValues={{
							datainicial: moment().startOf('month'),
							datafinal: moment(),
						}}
					>
						<Row gutter={16}>
							<Col xs={24} md={12} lg={6} xl={6} xxl={6}>
								<Fragment>
									<Form.Item
										name="empresa_id" 
										label="Empresa" 
										className="employee-select"
										rules={[{ required: true, message: "Campo obrigatório." }]}
									>
										<Select
											allowClear
											filterOption={(input, option) => (typeof option.children === 'string' ? option.children : option.children.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
											notFoundContent={this.state.companiesIsLoading ? <Spin size="small" /> : null}
											onSearch={this.fetchCompanies}
											onSelect={this.selectCompany}
											optionFilterProp="children"
											placeholder="Selecione a empresa"
											showSearch
											value={empresa_id}
											disabled={isDisabled || dataInicial > dataFinal}
										>
											{companies.map((company, index) => (
												<Select.Option key={index} value={company.id}>
													{company.nome}
												</Select.Option>
											))}
										</Select>
    							</Form.Item>
									<div className="employee-navigation-buttons">
										<Button type="link" icon={<i className="fas fa-chevron-left" />} disabled={dataInicial > dataFinal} className={this.state.isPreviousDisabled ? "disabled-button" : ""} onClick={this.goToPreviousCompany} />
										<Button type="link" icon={<i className="fas fa-chevron-right" />} disabled={dataInicial > dataFinal} className={this.state.isNextDisabled ? "disabled-button" : ""} onClick={this.goToNextCompany} />
									</div>
								</Fragment>
							</Col>
							<Col xs={24} md={12} lg={6} xl={6} xxl={6}>
								<Fragment>
									<Form.Item 
										name="funcionario_id" 
										label="Funcionário" 
										className="employee-select"
										rules={[{ required: true, message: "Campo obrigatório." }]}
									>
										<Select
											filterOption={false}
											allowClear
											placeholder="Selecione o funcionário"
											notFoundContent={employeesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
											onSearch={this.fetchEmployees}
											onDeselect={this.fetchEmployees}
											onSelect={this.selectEmployee}
											showSearch
											disabled={isDisabled || dataInicial > dataFinal}
										>
											{employees.map((item, index) => (
												<Select.Option key={index} value={item.id}>{item.nome}</Select.Option>
											))}
										</Select>
									</Form.Item>
									<div className="employee-navigation-buttons">
										<Button type="link" icon={<i className="fas fa-chevron-left" />} disabled={this.state.isPreviousDisabled || dataInicial > dataFinal} className={this.state.isPreviousDisabled ? "disabled-button" : ""} onClick={this.goToPreviousEmployee} />
										<Button type="link" icon={<i className="fas fa-chevron-right" />} disabled={this.state.isNextDisabled || dataInicial > dataFinal} className={this.state.isNextDisabled ? "disabled-button" : ""} onClick={this.goToNextEmployee} />
									</div>
								</Fragment>
							</Col>
							<Col xs={12} md={10} lg={4} xl={4} xxl={4}>
								<DatePickerWithMask 
									onChange={(e) => this.setState({ dataInicial: e.format("YYYY-MM-DD") })} 
									label="Data Inicial" 
									name="datainicial" 
									required={true} 
									disabled={isDisabled}
								/>
							</Col>
							<Col xs={12} md={10} lg={4} xl={4} xxl={4}>
								<DatePickerWithMask 
									onChange={(e) => {
										const dataFinal = e.format("YYYY-MM-DD");
										if (dataInicial > dataFinal) {
											message.error("A data final não pode ser menor que a data inicial.");
											this.setState({ dataFinal });
											this.setState({ dataFinalError: true })
											return;
										}
										this.setState({ dataFinal, dataFinalError: false }, () => {
											this.form.submit();
										});
									}}
									label="Data Final" 
									name="datafinal" 
									disabled={isDisabled}
									required={true}
									onKeyUp={(e) => {
										if (e.keyCode === 9) {
											this.form?.datafinal.focus();
										}
									}}
									rules={[
										{
											required: true,
											message: "Campo obrigatório.",
										},
										{
											validator(_, value) {
												if (!dataFinalError) {
													return Promise.resolve();
												}
												return Promise.reject(new Error("A data final não pode ser menor que a data inicial."));
											},
										},
									]}
								/>
							</Col>
							<Col xs={24} md={3} lg={2} xl={2} xxl={2} style={{ textAlign: "center", marginBottom: '5px' }}>
								{this.state.avatar ? (
									<Avatar
										size={100}
										src={this.state.avatar}
									/>
								) : (
									<i className="fad fa-user-circle avatar-placeholder" style={{fontSize: 100, color: "#b3b3b3"}} />
								)}
							</Col>
						</Row>

						<Col xs={24} sm={20} md={20} lg={20} xl={20} xxl={20}>
							<Row gutter={16} justify="end" className="group-buttons-actions">
								<div style={{ marginRight: '10px' }}>
									<Button.Group className="btn-group-actions" style={{ width: '100%', maxWidth: '110px' }}>
										<Tooltip placement="top" title="Gerenciar colunas">
											<Button 
												type="primary" 
												block 
												shape="round" 
												icon={<i className="far fa-columns" />} 
												onClick={this.columnsOpen}
												style={{ width: '50%' }} 
											/>
										</Tooltip>
										<Tooltip placement="top" title="Imprimir">
											<Button 
												type="primary" 
												block 
												shape="round" 
												icon={<i className="far fa-print" />} 
												onClick={this.printOpen}
												style={{ width: '50%' }} 
											/>
										</Tooltip>
									</Button.Group>
								</div>
								<div style={{ marginRight: '10px', width: '110px' }}>
									<Button type="primary" block shape="round" icon={<i className="fal fa-filter" />} onClick={this.filtersOpen}>Filtros</Button>
								</div>
								<div>
									<Button
										type="primary" 
										block 
										shape="round" 
										htmlType="submit" 
										icon={<i className="far fa-search" />}
										loading={isDisabled}
										disabled={isDisabled || (dataInicial > dataFinal)}>
										{isDisabled ? "Consultando" : "Consultar"}
									</Button>
								</div>
							</Row>
						</Col>
					</Form>

					{isSearching && (
						<Fragment>
							<Divider />
							<Table
								onPaginationChange={this.onPaginationChange}
								isLoading={this.state.isLoading}
								data={this.state.data}
								pagination={this.state.pagination}
								maxJoinColumns={this.state.maxJoinColumns}
								dataInicial={this.state.dataInicial}
								dataFinal={this.state.dataFinal}
								columns={this.columns()}
								columnsTotal={this.columnsTotal()}
								fetchGetAll={this.fetchGetAll}
								fetchGetItems={this.fetchGetItems}
								updateRow={this.updateRow}
								reloadRow={this.fetchGetAll}
								newJoinColumn={this.newJoinColumn}
								removeBatida={this.removeBatida}
								journeyOpen={this.journeyOpen}
							/>
						</Fragment>
					)}
				</div>

				<ModalColumns
					ref={el => this.columnsScreen = el}
					visible={this.state.columnsModalVisible}
					onComplete={this.columnsOnComplete}
					onClose={this.columnsOnClose}
				/>
				<ModalCreate
					ref={el => this.createScreen = el}
					onComplete={this.createOnComplete}
				/>
				<ModalEdit
					ref={el => this.editScreen = el}
					onComplete={this.editOnComplete}
				/>
				<ModalDiscard
					ref={el => this.discardScreen = el}
					onComplete={this.discardOnComplete}
				/>
				<ModalAjustes
					ref={el => this.ajustesScreen = el}
					visible={this.state.ajustesModalVisible}
					onComplete={this.ajustesOnComplete}
					onClose={this.ajustesOnClose}
				/>
				<ModalBancoAjustes
					ref={el => this.bancoAjustesScreen = el}
					visible={this.state.bancoAjustesModalVisible}
					onComplete={this.bancoAjustesOnComplete}
					onClose={this.bancoAjustesOnClose}
					empresa_id={this.state.empresa_id}
					funcionario_id={this.state.funcionario_id}
					currentEmployeeDepartment={this.state.currentEmployeeDepartment}
				/>
				<ModalJourneyShow
					ref={el => this.journeyShowScreen = el}
					visible={this.state.journeyShowModalVisible}
					onClose={this.journeyOnClose}
				/>
				<ModalFilters
					ref={el => this.filtersScreen = el}
					visible={this.state.filtersModalVisible}
					onComplete={this.filtersOnComplete}
					onClose={this.filtersOnClose}
				/>
			</QueueAnim>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		permissions: state.auth.userData.permissions,
	};
};

export default connect(mapStateToProps, null)(withRouter(Index));
