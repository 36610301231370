import React, { Component } from "react";
import * as PropTypes from "prop-types";
import axios from "axios";
import { Col, Table, Row, Spin, Button, Popconfirm, Select } from "antd";
import { API_ERRO_TYPE_CANCEL } from "../../../config/general";
import { companiesService, departmentsService } from "../../../redux/services";

import { DatePickerWithMask } from "../../../components"
import moment from "moment/moment";

class TracksTable extends Component {
	static propTypes = {
		showOnly: PropTypes.bool,
		values: PropTypes.arrayOf(PropTypes.any),
	}

	constructor(props) {
		super(props);

		if (props.values && props.values.length > 0) {
			this.state = {
				showOnly: this.props.showOnly ?? false,
				companiesIsLoading: false,
				companies: [],
				departmentsIsLoading: false,
				departments: [],
				deletedsOriginalTracks: [],

				tracks: props.values.map((track, index) => ({
					name: `Faixa - ${index + 1}`,
					action: 'editar',
					id: track.id,
					company_id: track.empresa_id,
					department_id: track.departamento_id,
					date: moment(track.data_banco_hora),
				}))
			};
		} else {
			this.state = {
				showOnly: this.props.showOnly ?? false,
				companiesIsLoading: false,
				companies: [],
				departmentsIsLoading: false,
				departments: [],
				tracks: [
					{
						name: 'Faixa - 1',
						id: Math.floor(Math.random() * 10001),
						company_id: null,
						department_id: null,
						date: null,
					},
				],
			};
		}

		this._axiosCancelCompaniesToken = null;
		this._axiosCancelDepartmentsToken = null;

		setTimeout(() => {
			this.fetchCompanies();
			this.fetchDepartments();
		}, 100);
	}

	fetchCompanies = () => {
		if (this._axiosCancelCompaniesToken) {
			this._axiosCancelCompaniesToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelCompaniesToken = axios.CancelToken.source();

		this.setState({
			companiesIsLoading: true,
		});

		companiesService.getAutocomplete({
			orderBy: "nome:asc",
			cancelToken: this._axiosCancelCompaniesToken.token,
		})
			.then((response) => {
				this.setState({
					companiesIsLoading: false,
					companies: response.data.data,
				});
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					companiesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	fetchDepartments = (value, index) => {
		if (this._axiosCancelDepartmentsToken) {
			this._axiosCancelDepartmentsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelDepartmentsToken = axios.CancelToken.source();

		// if (!value.trim().length) {
		// 	this.setState({
		// 		departmentsIsLoading: false,
		// 		departments: [],
		// 	});

		// 	return false;
		// }

		this.setState({
			departmentsIsLoading: true,
		});

		let params = {
			// search: value,
			orderBy: "nome:asc",
			cancelToken: this._axiosCancelDepartmentsToken.token,
		}

		departmentsService.getAutocomplete({
			...params
		})
			.then((response) => {
				this.setState({
					departmentsIsLoading: false,
					departments: response.data.data,
				});
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					departmentsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};


	onCreateRow = () => {
		this.setState({
			tracks: [
				...this.state.tracks,
				{
					name: `Faixa - ${this.state.tracks.length + 1}`,
					id: Math.floor(Math.random() * 10001),
					company_id: null,
					department_id: null,
					date: null,
				},
			],
		});
	}

	onRemoveRow = (id, action, index) => {
		if (action) {
			this.setState({
				deletedsOriginalTracks: [...this.state.deletedsOriginalTracks, {
					...this.state.tracks[index], action: 'deletar'
				}
				],

			})
		}

		this.setState({
			tracks: this.state.tracks.filter((item) => item.id != id),
		});
	}

	render() {
		const { tracks, companiesIsLoading, companies, departments, departmentsIsLoading, showOnly } = this.state;

		const columns = [
			{
				title: 'Empresa(s)',
				dataIndex: 'companies',
				width: 250,
				render: (_, { id, company_id }, index) => (
					<Select
						optionFilterProp="children"
						filterOption={(input, option) => (typeof option.children === 'string' ? option.children : option.children.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
						allowClear
						key={id + '_company'}
						disabled={showOnly}
						style={{ width: '200px' }}
						placeholder="Selecione a empresa"
						notFoundContent={companiesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
						showSearch
						defaultValue={company_id}
						onChange={(value) => {
							const newTracks = [...tracks];

							newTracks[index].company_id = value

							this.setState({ tracks: newTracks })
						}}
					>
						{/* <Select.Option value="todos">Todas</Select.Option> */}
						{companies.map((item, index) => (
							<Select.Option key={index} value={item.id}>
								{item.nome}
							</Select.Option>
						))}
					</Select>
				),
			},
			{
				title: 'Departamento(s)',
				dataIndex: 'departments',
				width: 300,
				render: (_, { id, department_id }, index) => (
					<Select
						optionFilterProp="children"
						filterOption={(input, option) => (typeof option.children === 'string' ? option.children : option.children.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
						allowClear
						key={id + '_department'}
						disabled={showOnly}
						style={{ width: '200px' }}	
						placeholder="Selecione o departamento"
						defaultValue={department_id}
						notFoundContent={departmentsIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
						onSearch={(value) => this.fetchDepartments(value, index)}
						onChange={(value) => {
							const newTracks = [...tracks];

							newTracks[index].department_id = value

							this.setState({ tracks: newTracks })
						}}
						showSearch
					>
						{/* <Select.Option value="todos">Todos</Select.Option> */}
						{departments.map((item, index) => (
							<Select.Option key={index} value={item.id}>{item.nome}</Select.Option>
						))}
					</Select>
				),
			},
			{
				title: 'Data inicial',
				dataIndex: 'data_inicial',
				width: 700,
				render: (_, { id, date }, index) => (
					<DatePickerWithMask key={id + '_date'} disabled={showOnly} isForm={false} value={date} onChange={(value) => {
						const newTracks = [...tracks];

						newTracks[index].date = value

						this.setState({ tracks: newTracks })
					}} />
				),
			},

		];

		if (!showOnly) {
			columns.push(
				{
					title: 'Ações',
					dataIndex: 'acoes',
					width: 120,
					render: (_, { id, action }, index) => (
						<Popconfirm placement="topRight" title="Deseja excluir essa faixa?" onConfirm={() => this.onRemoveRow(id, action, index)} okText="Sim" cancelText="Não">
							<Button danger shape="circle" icon={<i className="fal fa-times"></i>} />
						</Popconfirm>
					),
				},
			)
		}

		return (
			<div>
				<Table size="small" columns={columns} owKey='id' dataSource={tracks} pagination={false} />
				{(showOnly == false) && (<div className="text-right">
					<Button type="ghost" className="mt-15" onClick={() => this.onCreateRow()}>Adicionar nova faixa</Button>
				</div>)}
			</div>
		);
	}
}

export default TracksTable;